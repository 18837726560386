@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.white-logo {
	height: 140px;
	width: auto;
	display: block;
	margin: 0 auto; }

.products {
	h2 {
		color: $lgreen;
		font-weight: 700;
		text-align: center;
		display: block;
		font-size: 26px;
		padding-bottom: 30px; } }

.product-book {
	margin-bottom: 15px;
	background-color: $product_bg;
	padding: 15px;
	display: flex;
	flex-wrap: wrap; }

.product-text-book {
	span {
		font-size: 20px;
		color: $lgreen;
		font-weight: 700;
		padding-top: 20px;
		padding-bottom: 20px;
		display: block; }
	p {
		color: $dblue;
		font-weight: 300;
		font-size: 13px;
		line-height: 26px;
		text-align: justify; }
	strong {
		font-weight: 700;
		font-size: 13px;
		line-height: 26px;
		text-align: justify; } }

.product-image-book {
	height: 300px;
	//width: 200px
	position: relative;
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat; }

.product-book-order {
	align-self: center; }

.product-cd-wrap {}


.product-cd {
	background-color: $product_cd_wrap;
	background-position: center center;
	background-size: 90% 90%;
	background-repeat: no-repeat;
	position: relative;
	height: 175px;
	width: 175px;
	margin: 0 auto; }

.divider {
	display: block;
	position: relative;
	height: 50px;
	width: 100%; }

@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

h1 {
	color: $lgreen;
	font-weight: 700;
	text-align: center;
	display: block;
	font-size: 26px;
	padding-bottom: 30px; }
.text-background {
	background-color: $product_bg;
	padding: 25px 45px;
	margin-bottom: 15px;
	font-style: $font1;
	font-size: 13px;
	line-height: 26px;
	text-align: justify;
	color: $dblue; }

.text-style {
	h3 {
		color: $lgreen;
		font-size: 20px;
		font-weight: 700;
		padding-top: 10px;
		padding-bottom: 20px;
		display: block;
		&:not(:first-child):before {
			content: "";
			border-top: 2px solid $lgreen;
			height: 5px;
			width: 80%;
			display: block;
			margin: 0 auto;
			padding-top: 20px; } } }

.main-home {
	display: block;
	height: 630px;
	width: 100%;
	background-image: url("../images/svg/header_s.jpg");
	background-position: top center;
	background-size: cover;
	text-align: center; }
.main-home-wrapper {
	position: relative;
	top: 40%;
	transform: translateY(-60%); }
.main-header-heading, .main-header-heading h1 {
	background-color: $dgreen;
	padding: 0px 50px;
	margin: 0;
	font-size: 60px;
	font-weight: 700;
	color: $white;
	display: inline-block;
	position: relative;
	width: auto; }
.main-header-subheading, .main-header-subheading h2 {
	background-color: $lgreen;
	padding: 20px 50px;
	margin: 0;
	line-height: 30px;
	font-size: 28px;
	font-weight: 300;
	color: $white;
	display: inline-block;
	position: relative;
	width: auto; }
.main-header-btn {
	background-color: $lgreen;
	width: 220px;
	height: 75px;
	color: $white;
	display: inline-block;
	padding-top: 26px;
	margin-top: 35px;
	border-radius: 75px;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 700;
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		color: $white;
		background-color: $orange; } }
.main-home-services {
	position: relative;
	display: block;
	top: 240px; }
.bg1 {
	background-image: linear-gradient($angle, $menufontcolor 1%, $dblue 100%); }
.bg2 {
	background-image: linear-gradient($angle, $dblue 1%, $dgreen 100%); }
.bg3 {
	background-image: linear-gradient($angle, $dgreen 1%, $lgreen 100%); }
.service-item {
	height: 170px;
	img {
		height: 160px;
		width: 160px;
		display: block;
		margin: -80px auto; }
	p {
		color: white;
		font-size: 20px;
		font-weight: 700;
		word-spacing: 99999999px;
		margin-top: 100px;
		line-height: 25px; } }

.main-home-text {
	padding-top: 140px;
	padding-bottom: 80px;
	img {
		height: 140px;
		width: auto;
		display: block;
		margin: 0 auto; }
	h2 {
		font-weight: 700;
		color: $lgreen;
		text-align: center;
		font-size: 28px;
		padding-bottom: 30px;
		strong {
			color: $dblue !important; } }
	p {
		text-align: center;
		color: $textfontcolor;
		line-height: 25px;
		font-size: 16px;
		strong {
			&:nth-child(2) {
				color: $orange !important; } } } }


.main-kurzy {
	background-color: #f6f6f6;
	height: 820px; }
.akcia-wrapper {
	padding-bottom: 25px; }
.akcia {
	background-color: #fafafa;
	border: 1px solid $bordercolor;
	height: 555px; }
.akcia-title {
	color: $lgreen;
	font-size: 19px;
	font-weight: 700;
	text-align: center;
	padding-top: 30px;
	padding-left: 20px;
	padding-right: 20px;
	height: 120px;
	overflow: hidden;
	strong {
		font-weight: 700; } }
.akcia-popis, .akcia-popis p {
	color: $dblue;
	font-weight: 300;
	font-size: 13px;
	text-align: center;
	strong {
		font-weight: 700; } }
.akcia-info {
	border: 1px solid $bordercolor;
	text-align: center;
	font-size: 13px;
	span {
		display: block;
		padding-top: 10px;
		color: $lgreen;
		font-weight: 700;
		text-transform: uppercase; } }
.akcia-cena {
	padding-top: 15px;
	padding-bottom: 5px;
	color: $lgreen;
	font-size: 24px;
	font-weight: 300;
	text-align: center;
	b {
		font-weight: 700; } }
.akcia-btn {
	background-color: $orange;
	color: $white;
	text-align: center;
	padding-top: 22px;
	border-radius: 75px;
	height: 70px;
	max-width: 160px;
	font-size: 15px;
	font-weight: 700;
	display: block;
	margin: 0 auto;
	&:hover,
	&:focus,
	&:active {
		background-color: $lgreen;
		color: $white; } }
.main-moreinfo {
	display: block;
	height: 560px;
	width: 100%;
	background-image: url("../images/svg/bottom_image_s.jpg");
	background-position: top center;
	background-size: cover; }
.main-moreinfo-text1,
.main-moreinfo-text1 p,
.main-moreinfo-btn-1,
.main-moreinfo-text2,
.main-moreinfo-text2 p,
.main-moreinfo-btn-2 {
	display: inline-block;
	font-size: 27px;
	font-weight: 700; }
.main-moreinfo-text1, .main-moreinfo-text1 p {
	color: $dblue;
	padding-top: 75px; }
.main-moreinfo-btn-1 {
	margin-top: 30px;
	padding: 20px 130px;
	background-color: $lgreen;
	color: $white;
	border-radius: 75px;
	&:hover, &:focus, &:active {
		color: $white;
		background-color: $orange; } }
.main-moreinfo-text2, .main-moreinfo-text2 p {
	color: $lgreen;
	padding-top: 50px; }
.main-moreinfo-btn-2 {
	margin-top: 20px;
	padding: 20px 130px;
	background-color: $dgreen;
	color: $white;
	border-radius: 75px;
	&:hover {
		color: $white;
		background-color: $orange; } }

.main-kurzy-heading, .main-kurzy-heading span {
	display: block;
	padding-top: 60px;
	color: $dgreen;
	font-size: 26px;
	text-align: center;
	strong {
		font-weight: 400 !important;
		color: $dblue; } }
.main-kurzy-subheading, .main-kurzy-subheading span {
	display: block;
	color: $dblue;
	font-size: 15px;
	text-align: center;
	padding-bottom: 30px; }



/* FOOTER */

.foot_part1 {
	display: block;
	height: 230px;
	width: 100%;
	background-color: $dblue; }
.logo-footer {
	max-height: 130px;
	width: auto;
	display: block;
	margin: 0 auto;
	padding-top: 30px;
	position: relative; }

.foot_part2 {
	display: block;
	height: 80px;
	width: 100%;
	background-color: $footerblue; }
.sixnet-logo {
	padding-top: 25px;
	font-family: $font1;
	color: $white;
	font-weight: 400;
	font-size: 13px;
	img {
		height: 32px;
		width: 32px;
		display: inline-block; } }
.mid-foot {
	text-align: center;
	color: $white;
	padding-top: 32px;
	font-size: 13px;
	a {
		color: $white;
		&:hover {
			color: $orange; } } }
.footer-facebook {
	width: 24px;
	height: 24px;
	display: block;
	margin-left: auto;
	margin-top: 28px; }


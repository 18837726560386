$primaryColor: #005eb2;		//tmavo modra
$secondaryColor: #05ca00;	//zelena pre hvoer
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;


$gutter: 15px;

/* SITE VARIABLES */

$font1: 'Merriweather Sans', sans-serif;

$menufontcolor: #0e2d3f;
$textfontcolor: #26241f;

$bordercolor: #ebebeb;

$white: #fff;
$orange: #ec6923;
$lgreen: #418e86;
$dgreen: #286b73;
$dblue: #1d4658;
$footerblue: #0e2d3f;

$product_bg: #f8f8f8;
$product_cd_wrap: #f2f2f2;

$facebookblue: #3B5998;




$angle: 35deg;


@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

@media (max-width: 991px) {
	/* BASE CLASSES */
	.hidesm {
		display: none; }
	.showsm {
		display: block; }
	.main-kurzy {
		height: auto; }

	/* MORE INFO PART */
	.main-moreinfo {
		height: auto; }
	.main-moreinfo-text1,
	.main-moreinfo-text1 p,
	.main-moreinfo-btn-1,
	.main-moreinfo-text2,
	.main-moreinfo-text2 p,
	.main-moreinfo-btn-2 {
		font-size: 18px;
		padding-top: 20px;
		margin-top: 10px; }
	.main-moreinfo-btn-2 {
		margin-bottom: 10px; }
	.main-moreinfo-btn-1,
	.main-moreinfo-btn-2 {
		padding: 20px 30px; }
	/* FOOTER */
	.foot_part1 {
		height: auto;
		img {
			padding-top: 20px;
			padding-bottom: 20px; } }
	.foot_part2 {
		height: auto;
		min-height: 150px; }
	.footer-facebook {
		position: relative;
		margin-top: 0px;
		top: -30px; }
	.mid-foot {
		padding-top: 0px; } }

@media (max-width: 768px) {
	.main-home-services {
		top: 120px; }
	.service-item {
		height: 120px;
		p {
			display: inline-block;
			margin: 0;
			width: 69%;
			margin-top: 30px; }
		img {
			display: inline-block;
			margin: 0;
			width: 30%;
			height: 100%;
			padding: 15px;
			float: left; } } }

@media (max-width: 550px) {
	/* HEADER PART */
	.main-header-heading, .main-header-heading h1 {
		font-size: 26px; }
	.main-header-subheading {
		font-size: 18px; }
	.logo-header {
		height: 55px;
		margin-top: 15px; } }

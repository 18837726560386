@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400,700&subset=latin-ext');
.hideimg {
  table {
    width: 100%;
    td {
      &:nth-child(1) {
        width: 15%; }
      &:nth-child(2) {
        width: 65%; }
      &:nth-child(3) {
        width: 20%; }
      img {
        display: block;
        margin: 0 auto; } } } }
@media (max-width: 550px) {
  .hideimg {
    img {
      display: none; } } }

table {
  width: 100%; }
textarea {
  resize: none; }
a, span, p, h1, h2, h3, h4, h5, h6, div, button, ul, li {
  font-family: $font1 !important; }
a {
  color: $orange;
  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $lgreen; } }

.translate-button {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .25s ease-out,color .25s ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: .85em 1em;
  margin: 1rem 0 1rem;
  font-size: .9rem;
  background-color: #ed6c08;
  color: #fefefe;
  text-transform: uppercase; }

.translate-button:hover {
  background-color: #c95c07;
  color: #fefefe; }

.btn-nick {
  background-color: $orange;
  border-radius: 75px;
  padding: 20px 30px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 10px;
  &:hover, &:active, &:focus {
    background-color: $lgreen;
    color: white; } }


@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';


.navbar {
	margin-bottom: 0px; }
.navbar-default {
	background-image: none;
	background-color: $white;
	//background-color: lightgreen
	box-shadow: none;
	border-radius: 0px;
	border-color: transparent;
	height: 130px; }
.navbar-nav {
	margin-top: 32px; }
.navbar-collapse {
	float: right; }
.navbar-default .navbar-nav {
	> li {
		z-index: 5;
		> a {
			color: $menufontcolor;
			font-size: 13px;
			padding: 20px 30px;
			text-transform: uppercase;
			text-shadow: none;
			&:hover {
				color: $white;
				border-radius: 75px;
				background-image: none;
				background-color: $lgreen; } } }


	.active, .hovered {
		> a {
			color: $white;
			border-radius: 75px;
			background-image: none;
			background-color: $lgreen;
			&:hover {
				color: $white;
				background-image: none;
				background-color: $lgreen; } } }
	.menu_level_1 {
		z-index: -1;
		left: 5px;
		display: none;
		position: absolute;
		background-color: $dgreen;
		margin-left: 0;
		padding-left: 0;
		margin-top: -20px;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
		.active {
			a {
				background-color: transparent !important;
				color: $orange; } }
		> li {
			list-style: none;
			padding-top: 12px;
			&:first-child {
				padding-top: 30px; }
			&:not(:last-child):after {
				content: "";
				display: block;
				width: 50%;
				height: 1px;
				margin: 0 auto;
				background-color: $lgreen;
				top: 7px;
				position: relative; }
			&:last-child {
				padding-bottom: 7px; }
			> a {
				color: $white;
				font-size: 11px;
				font-weight: 300;
				&:hover {
					color: $orange; } } } } }

.navbar-nav li:hover .menu_level_1 {
	display: block !important; }
.menu_level_1:hover {
	display: block !important; }

.menu-facebook {
	margin-top: 25px; }
.menu-facebook-icon {
	height: 32px;
	width: 32px;
	display: inline-block; }
.menu-facebook-text {
	font-size: 13px;
	font-weight: 300;
	color: $menufontcolor;
	display: inline-block;
	line-height: 15px;
	margin-top: 10px;
	top: 8px;
	position: relative; }

/* FOOTER MENU */

.foot-menu {
	text-align: center;
	margin-top: 30px;
	li {
		display: inline-block;
		padding: 15px 25px;
		a {
			color: white;
			font-size: 13px;
			text-transform: uppercase;
			&:hover {
				color: $orange; } } } }

/* MOBILE MENU */

@media (min-width: 768px) and (max-width: 991px) {
	.navbar-collapse.collapse {
		display: none !important; }

	.navbar-collapse.collapse.in {
		display: block !important; }

	.navbar-header .collapse, .navbar-toggle {
		display: block !important; }

	.navbar-header {
		float: none; } }

@media (max-width: 991px) {
	.logo-header {
		max-height: 75px; }
	.menu-facebook {
		margin-top: 0px;
		margin-bottom: 10px; }
	.navbar-default .navbar-nav .menu_level_1 {
		position: relative;
		left: 0px; }
	.navbar-toggle {
		margin-top: 0px; }
	.navbar-collapse {
		width: 100%;
		background-color: white;
		border-color: transparent !important; }
	.navbar-default {
		min-height: 130px;
		height: auto; } }


